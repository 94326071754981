import React, { memo, useEffect, useState, useRef, useCallback } from 'react'

import { Button, CircularProgress, IconButton, makeStyles, TextField } from '@material-ui/core'
import { isEqual, isEmpty } from 'lodash'
import { FilterSearchCheckboxesProps, ICheckboxItem } from '../models/filter-item-search-checkboxes.props'
import FilterItemCheckboxSearch from './FilterItemCheckboxSearch'
import { useLocation } from 'react-router-dom'
import { decode } from '@root-gipro/utils/helpers/queryString'
import { IUrlsSearch } from '@root-gipro/modules/filter/models/interfaces/filter-state.model'

const useStylesBtn = makeStyles(theme => ({
	root: {
		backgroundColor: 'transparent',
		fontWeight: 400,

		'&:hover': {
			backgroundColor: 'transparent',
			fontWeight: 500,
		},
	},
}))

const useStylesReddit = makeStyles(theme => ({
	root: {
		border: '1px solid #e2e2e1',
		overflow: 'hidden',
		borderRadius: 4,
		paddingRight: 0,
		height: 'auto',
		backgroundColor: '#fff',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:hover': {
			backgroundColor: '#fff',
		},
		'&$focused': {
			backgroundColor: '#fff',
			borderColor: theme.palette.primary.main,
		},
		'& .MuiFilledInput-inputHiddenLabel.MuiFilledInput-inputMarginDense': {
			paddingTop: 7,
			paddingBottom: 6,
		},
		'& .MuiIconButton-root': {
			padding: '6px',
		},
	},
	focused: {},
}))

const ITEMS_PER_LOAD = 50

const FilterItemSearchCheckbox: React.FC<FilterSearchCheckboxesProps> = memo(
	({ value, setValue, placeholder, isDisable, fieldName, items, isMultiple }) => {
		const [checkedItems, setCheckboxItems] = useState(value || [])
		const [query, setQuery] = useState('')
		const [filteredItems, setFilteredItems] = useState<ICheckboxItem[]>([])
		const [visibleItems, setVisibleItems] = useState<ICheckboxItem[]>([])
		const [loadedCount, setLoadedCount] = useState(ITEMS_PER_LOAD)
		const [isLoading, setIsLoading] = useState(false)
		const location = useLocation()
		const url: string = location.pathname.slice(1)
		const scrollContainerRef = useRef<HTMLDivElement | null>(null)

		const getParams = (): any => {
			const urlsSearch: string | null = localStorage.getItem('urlsSearch')
			let urlsSearhParsed: IUrlsSearch = urlsSearch ? JSON.parse(urlsSearch) : {}

			if (!isEmpty(urlsSearhParsed[url])) {
				return decode(urlsSearhParsed[url] as string)
			}
		}

		let params = getParams()

		const classes2 = useStylesBtn()
		const classes = useStylesReddit()

		useEffect(() => {
			const searchParams: any = params?.[fieldName] ? params?.[fieldName] : null
			if (items.length && searchParams) {
				const defaultChecked: any[] = []
				items.forEach(item => {
					if (
						searchParams.find((el: string) => {
							return url === 'purchases' || url === 'purchases/table' ? el === item?.code : el === item.id
						})
					) {
						defaultChecked.push(item.name)
					}
				})
				setCheckboxItems(defaultChecked)
			}
			setFilteredItems(items)
			setLoadedCount(ITEMS_PER_LOAD)
		}, [items])

		useEffect(() => {
			if (!isEqual(value, checkedItems)) {
				setCheckboxItems(value || [])
			}
			if (value === undefined) {
				setCheckboxItems([])
				setQuery('')
			}
		}, [value])

		useEffect(() => {
			setValue(fieldName, checkedItems)
		}, [checkedItems])

		useEffect(() => {
			const searchText = query.toLowerCase().trim()

			const newFilteredItems = items.filter(
				({ name, inn }: ICheckboxItem) =>
					name.toLowerCase().includes(searchText) || (fieldName === 'groupId' && inn?.toString().includes(searchText))
			)

			setFilteredItems(newFilteredItems)
			setLoadedCount(ITEMS_PER_LOAD)
		}, [query, items, fieldName])

		useEffect(() => {
			// Отображаем нужное количество элементов после фильтрации
			setVisibleItems(filteredItems.slice(0, loadedCount))
		}, [filteredItems, loadedCount])

		const handleScroll = useCallback(() => {
			if (scrollContainerRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current
				if (scrollTop + clientHeight >= scrollHeight - 10 && !isLoading) {
					setIsLoading(true)
					setTimeout(() => {
						setLoadedCount(prev => prev + ITEMS_PER_LOAD)
						setIsLoading(false)
					}, 300) // Имитация задержки для UX
				}
			}
		}, [isLoading])

		useEffect(() => {
			const container = scrollContainerRef.current
			if (container) {
				container.addEventListener('scroll', handleScroll)
			}
			return () => {
				if (container) {
					container.removeEventListener('scroll', handleScroll)
				}
			}
		}, [handleScroll])

		const resetCheckedItems = () => {
			setCheckboxItems([])
			setQuery('')
		}

		const updateData = (value: string) => {
			if (isMultiple) {
				setCheckboxItems((prev: any) =>
					prev.includes(value) ? prev.filter((item: any) => item !== value) : [...prev, value]
				)
			} else {
				setCheckboxItems([value])
			}
		}

		return (
			<div style={{ position: 'relative' }}>
				{fieldName.split('.')[0] === 'uncCell' && <h4 style={{ marginTop: '16px' }}>{placeholder}</h4>}

				{checkedItems.length > 0 && (
					<Button
						type='reset'
						variant='text'
						size='small'
						color='primary'
						classes={classes2}
						className='aside-filter__reset link link-blue'
						style={{ textTransform: 'none', padding: 0 }}
						disableFocusRipple
						disableRipple
						onClick={resetCheckedItems}
					>
						Очистить
					</Button>
				)}

				<TextField
					value={query}
					placeholder={fieldName === 'groupId' ? placeholder : 'Поиск...'}
					margin='dense'
					hiddenLabel
					variant='filled'
					InputProps={{
						classes,
						disableUnderline: true,
						endAdornment: query && (
							<IconButton type='button' onClick={() => setQuery('')} className='reset-icon'>
								<span className='close-icon' />
							</IconButton>
						),
					}}
					color='primary'
					onChange={e => setQuery(e.target.value)}
					fullWidth
				/>

				<div
					ref={scrollContainerRef}
					style={{ maxHeight: '230px', paddingBottom: '8px', overflowY: 'scroll', overflowX: 'hidden' }}
				>
					{visibleItems.map((item: ICheckboxItem) => (
						<FilterItemCheckboxSearch
							key={item.id}
							value={false}
							queryValue={query.toLowerCase().trim()}
							name={item.name}
							projectsCount={item.projectsCount}
							fieldName={fieldName}
							isDisable={isDisable}
							updateData={updateData}
							isChecked={checkedItems}
						/>
					))}
					{(isLoading || !items.length) && (
						<div style={{ display: 'flex', justifyContent: 'center', padding: '12px 0' }}>
							<CircularProgress size={24} />
						</div>
					)}
				</div>
			</div>
		)
	}
)

export default FilterItemSearchCheckbox
